import React, { memo, useEffect, useState } from "react"
import style from "styles/blog.module.css"
import Link from "next/link"
import moment from 'moment'
import useLocale from '../../../../hooks/useLocale'

const Blog = () => {
	const {blogSection} = useLocale()
	const [data, setData] = useState([
		{
			post_link: "/",
			banner: "image/postMedia.webp",
			title: "Media Gallery for January 2022",
			author: "TheWolf",
			date: "2022-01-31 14:15:12",
		},
		{
			post_link: "/",
			banner: "image/postDemo.webp",
			title: "Playable demo November 2021",
			author: "TheWolf",
			date: "2022-01-31 14:15:12",
		},
		{
			post_link: "/",
			banner: "image/postVideo.webp",
			title: "Video Update October 2021",
			author: "TheWolf",
			date: "2022-01-31 14:15:12",
		},
		{
			post_link: "/",
			banner: "image/postTechniques.webp",
			title: "Techniques: Ground",
			author: "TheWolf",
			date: "2022-01-31 14:15:12",
		},
	]
	)
	const getPosts = async () => {
		try {
			const request = await fetch('https://blog.metaisland.gg/wp-json/api/v1/blog/')
			if (request.ok) {
				const postData = await request.json()
				setData(postData.slice(0, 4))
			}
		} catch (e) {
		}
	}
	useEffect(() => {
		getPosts()
	}, [])
	return (
		<div id="blog" className={style.blog}>
			<div className={style.container}>
				<h2 className={style.title} data-aos-once="true" data-aos="slide-up">{blogSection.title}</h2>
				<div className={style.blockPosts}
						 data-aos-once="true" data-aos="flip-up" data-aos-anchor-placement="center"
				>
					{data.map((item, index) => {
						return (
							<div key={item.title} className={style.post}>
								<Link href={item.post_link}><a target="_blank" aria-label="Media Gallery" className={style.imgPost}>
									<img
										src={item.banner ? item.banner : './metaImage.png'}
										alt="blog image"
									/>
								</a>
								</Link>
								<h3 className={style.namePost}>{item.title}</h3>
								<p className={style.aboutPost}>{moment(item.date).format('MMMM D, YYYY')} by {item.author}</p>
							</div>
						)
					})}
				</div>
				<div className={style.blockBtn} data-aos-once="true" data-aos="flip-down">
					<button onClick={() => window.open('https://blog.metaisland.gg', '_blank')}
						className={style.postBtn}>{blogSection.button}
					</button>
				</div>
			</div>
		</div>
	)
}

export default memo(Blog)
